/*jslint vars: true, plusplus: true, devel: true, nomen: true, indent: 4, maxerr: 50 */
(function(){
	'use strict';

$( document ).ready(function() {

    $('table.calendar td').each (function() {
      var th = $(this).closest('table').find('th').eq( this.cellIndex );
      var thContent = $(th).html();
      $(this).attr('data-th',thContent);
    });
});

})(); // END OF USE STRICT FUNCTION